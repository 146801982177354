//
// misc
//

// scroll down
.scroll-down {
  transform: rotate(-45deg);
  position: absolute;
  bottom: rem(80);
  left: 50%;
  width: rem(16);
  height: rem(16);
  margin-left: -12px;
  border-left: rem(2) solid $white;
  border-bottom: rem(2) solid $white;
  -webkit-animation: scrollDown 1.4s infinite;
  animation: scrollDown 1.4s infinite;
  z-index: 8;
}

@keyframes scrollDown {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-100%, 100%);
    opacity: 0;
  }
}

.rotate {
  animation: rotate 16s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.grid {
  position: relative;

  > * {
    opacity: 0;
    position: absolute;

    &[class*="-shown"] {
      opacity: 1;
    }
  }
}

[data-isotope] {
  position: relative;
}

.logo {
  height: auto;
  width: rem(120);
  max-width: 100%;

  &[class*="-sm"] {
    width: rem(80);
  }
}

a.brand-logo {
  display: inline-block !important;
  font-size: 50px;
  color: #fff;
  line-height: 50px;
  letter-spacing: 5px;
  position: relative;

  span {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 5px;
    position: absolute;
    top: 2px;
    transform: translateX(-3px);
    font-weight: 500;
  }

  &:hover {
    color: #fff;
  }
}

.bg-resoundly { background-color: #403ED9;  }
.bg-huntkit { background-color: #141826;  }
.bg-planivo { background-color: #2a4464;  }
.bg-rnsk { background-color: #26236b;  }
.bg-whisky-journal { background-color: #2C2929;  }
.bg-drive-it-away { background-color: #6b154f;  }

.portfolio-mobile {
  width: 80%;
  max-width: 80%;
  margin: 0 auto;
}

.portfolio-laptop {
  @media screen and (min-width: 990px) {
    transform: translate(-120px, 44px);
    width: 150%;
    max-width: 150%;
  }

  @media screen and (min-width: 1200px) {
    transform: translate(-100px, 14px);
    width: 150%;
    max-width: 150%;
  }

  @media screen and (min-width: 1400px) {
    transform: translate(-60px, -36px);
    width: 130%;
    max-width: 130%;
  }
}
