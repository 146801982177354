@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "background-color"), "values"),
              (
                "blue": $blue,
                "indigo": $indigo,
                "purple": $purple,
                "pink": $pink,
                "red": $red,
                "orange": $orange,
                "yellow": $yellow,
                "green": $green,
                "teal": $teal,
                "cyan": $cyan,
                "white": $white,
                "gray": $gray-600,
                "gray-dark": $gray-800,
                "black": $black,
              )
            ),
        )
      ),
    "min-viewport-height":
      map-merge(
        map-get($utilities, "min-viewport-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "min-viewport-height"), "values"),
              (
                25: 25vh,
                50: 50vh,
                75: 75vh,
              )
            ),
        )
      ),
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                10: 10%,
                20: 20%,
                30: 30%,
                40: 40%,
                50: 50%,
                60: 60%,
                70: 70%,
                80: 80%,
                90: 90%,
                100: 100%,
              )
            ),
        )
      ),
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              (
                "blue": $blue,
                "indigo": $indigo,
                "purple": $purple,
                "pink": $pink,
                "red": $red,
                "orange": $orange,
                "yellow": $yellow,
                "green": $green,
                "teal": $teal,
                "cyan": $cyan,
                "white": $white,
                "gray": $gray-600,
                "gray-dark": $gray-800,
                "black": $black,
              )
            ),
        )
      ),
    "bg-opaque": (
      property: background-color,
      class: bg-opaque,
      values: (
        "blue": rgba($blue, 0.1),
        "indigo": rgba($indigo, 0.1),
        "purple": rgba($purple, 0.1),
        "pink": rgba($pink, 0.1),
        "red": rgba($red, 0.1),
        "orange": rgba($orange, 0.1),
        "yellow": rgba($yellow, 0.1),
        "green": rgba($green, 0.1),
        "teal": rgba($teal, 0.1),
        "cyan": rgba($cyan, 0.1),
        "white": rgba($white, 0.1),
        "gray": rgba($gray-600, 0.1),
        "gray-dark": rgba($gray-800, 0.1),
        "black": rgba($black, 0.1),
        "border-color": rgba($gray-200, 0.1),
        "primary": rgba($primary, 0.1),
        "light": rgba($light, 0.1),
        "dark": rgba($dark, 0.1),
      ),
    ),
    "text-hover": (
      property: color,
      class: text,
      state: hover,
      values: map-merge($theme-colors, $colors),
    ),
    "bg-hover": (
      property: background-color,
      class: bg,
      state: hover,
      values: map-merge($theme-colors, $colors),
    ),
  )
);

.zindex-0 { z-index: 0; }
.zindex-1 { z-index: 1; }
.zindex-2 { z-index: 2; }

.font-serif { font-family: serif; }
